'use client'

import { forwardRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContext, useContextSelector } from 'use-context-selector'
import { useRouter, useSearchParams } from 'next/navigation'
import style from '../list.module.css'
import CreateFromDSLModal, { CreateFromDSLModalTab } from '@/app/components/app/create-from-dsl-modal'
import { useProviderContext } from '@/context/provider-context'
import SettingsModal, { type CreateAppParams } from '@/app/components/app/overview/settings'
import { importApp } from '@/service/apps'
import AppsContext from '@/context/app-context'
import Toast, { ToastContext } from '@/app/components/base/toast'
import i18n from '@/i18n/i18next-config'
import {
  fetchPublishedWorkflow,
  publishWorkflow,
  syncWorkflowDraft,
} from '@/service/workflow'
import type { Node } from '@/app/components/workflow/types'
import { BlockEnum } from '@/app/components/workflow/types'
import type { DatasetMode } from '@/app/components/workflow/nodes/knowledge-retrieval/types'
import { bindShopifyShopWithChatbot } from '@/service/integrations'
import classNames from '@/utils/classnames'

export type CreateAppCardProps = {
  onSuccess?: () => void
  disabled?: boolean
}

const CreateAppCard = forwardRef<HTMLAnchorElement, CreateAppCardProps>(({ onSuccess, disabled = false }, ref) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  const { onPlanInfoChanged } = useProviderContext()

  const [showNewAppDialog, setShowNewAppDialog] = useState(false)
  const mutateApps = useContextSelector(AppsContext, state => state.mutateApps)
  const router = useRouter()
  const { notify } = useContext(ToastContext)
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const dslUrl = searchParams.get('remoteInstallUrl') || undefined

  const [showNewAppTemplateDialog, setShowNewAppTemplateDialog] = useState(false)
  const [showNewAppModal, setShowNewAppModal] = useState(false)
  const [showCreateFromDSLModal, setShowCreateFromDSLModal] = useState(!!dslUrl)

  const activeTab = useMemo(() => {
    if (dslUrl)
      return CreateFromDSLModalTab.FROM_URL

    return undefined
  }, [dslUrl])

  const onCreateApp = async (params: CreateAppParams) => {
    try {
      const app = await importApp({
        name: params.name,
        icon: params.icon,
        icon_background: params.icon_background,
        white_list_websites: params.white_list_websites,
        default_language: params.default_language,
        bot_type: params.bot_type,
      })
      const { id, model_config: { dataset_configs, video_dataset_configs, shopify_dataset_configs, product_list_dataset_configs, shopify_website_crawl_dataset_configs } } = app
      // 获取workflow配置
      const { graph, features, hash } = await fetchPublishedWorkflow(`/apps/${id}/workflows/publish`)
      // 文档知识库节点
      const textKnowledgeNode = (graph.nodes as Node<{ dataset_mode: DatasetMode; dataset_ids: string[] }>[]).find(node => node.data.type === BlockEnum.KnowledgeRetrieval && (node.data?.dataset_mode === 'text' || !node.data?.dataset_mode))
      const textDatasetIds = dataset_configs.datasets.datasets.map(({ dataset }: any) => dataset.id)
      // 视频知识库节点
      const videoKnowledgeNode = (graph.nodes as Node<{ dataset_mode: DatasetMode; dataset_ids: string[] }>[]).find(node => node.data.type === BlockEnum.KnowledgeRetrieval && node.data.dataset_mode === 'video')
      const videoDatasetIds = video_dataset_configs.datasets.datasets.map(({ dataset }: any) => dataset.id)
      // shopify知识库节点
      const shopifyKnowledgeNode = (graph.nodes as Node<{ dataset_mode: DatasetMode; dataset_ids: string[] }>[]).find(node => node.data.type === BlockEnum.KnowledgeRetrieval && (node.data?.dataset_mode === 'shopify_product'))
      const shopifyDatasetIds = shopify_dataset_configs.datasets.datasets.map(({ dataset }: any) => dataset.id)
      // shopify商品知识库
      const shopifyProductNode = (graph.nodes as Node<{ tool_name: string; tool_parameters: { data: { type: 'mixed'; value: string } } }>[]).find(node => node.data.type === BlockEnum.Tool && node.data.tool_name === 'show_product_card')
      const shopifyProductDatasetIds = product_list_dataset_configs?.datasets.datasets.map(({ dataset }: any) => dataset.id)
      // shopify非商详页爬取网页知识库
      const shopifyWebsiteCrawlKnowledgeNode = (graph.nodes as Node<{ dataset_mode: DatasetMode; dataset_ids: string[] }>[]).find(node => node.data.type === BlockEnum.KnowledgeRetrieval && node.data?.dataset_mode === 'shopify_website_crawl')
      const shopifyWebsiteCrawlDatasetIds = shopify_website_crawl_dataset_configs.datasets.datasets.map(({ dataset }: any) => dataset.id)
      // 把知识库添加到workflow应用中去
      const otherNode = graph.nodes.filter(node => node.data.type !== BlockEnum.KnowledgeRetrieval && !(node.data.type === BlockEnum.Tool && node.data.tool_name === 'show_product_card'))

      const allNodes = [
        ...otherNode,
        {
          ...textKnowledgeNode,
          data: {
            ...textKnowledgeNode?.data,
            dataset_ids: textDatasetIds,
          },
        },
        {
          ...videoKnowledgeNode,
          data: {
            ...videoKnowledgeNode?.data,
            dataset_ids: videoDatasetIds,
          },
        },
        {
          ...shopifyKnowledgeNode,
          data: {
            ...shopifyKnowledgeNode?.data,
            dataset_ids: shopifyDatasetIds,
          },
        },
      ]

      if (shopifyProductNode) {
        allNodes.push({
          ...shopifyProductNode,
          data: {
            ...shopifyProductNode?.data,
            tool_parameters: {
              ...shopifyProductNode?.data.tool_parameters,
              dataset_id: {
                type: 'mixed',
                value: shopifyProductDatasetIds?.[0] ?? '',
              },
            },
          },
        })
      }

      if (shopifyWebsiteCrawlKnowledgeNode) {
        allNodes.push({
          ...shopifyWebsiteCrawlKnowledgeNode,
          data: {
            ...shopifyWebsiteCrawlKnowledgeNode?.data,
            dataset_ids: shopifyWebsiteCrawlDatasetIds,
          },
        })
      }

      await syncWorkflowDraft({
        url: `/apps/${id}/workflows/draft`,
        params: {
          graph: {
            ...graph,
            nodes: allNodes,
          },
          features,
          hash,
        },
      })
      await publishWorkflow(`/apps/${id}/workflows/publish`)
      onPlanInfoChanged()

      if (onSuccess)
        onSuccess()

      if (searchParams.get('shopify_bind_id')) {
        // shopify逻辑
        try {
          await bindShopifyShopWithChatbot(`/apps/${app.id}/shopify_bind`, { bind_id: searchParams.get('shopify_bind_id') })
          setTimeout(() => {
            router.replace(`/app/${app.id}/configuration`)
            notify({
              type: 'success',
              message: systemT('app.shopify.bindSuccessTip'),
            })
          }, 0)
          return
        }
        catch (err) {
          const error = await err.json()
          if (error.status === 403 && error.code === 'shopify_quota_exceeded') {
            Toast.notify({
              type: 'error',
              message: systemT('app.shopify.limit'),
            })
          }
          return
        }
      }

      notify({ type: 'success', message: systemT('app.newApp.appCreated') })
      mutateApps()
      router.push(`/app/${app.id}/overview`)
    }
    catch (e) {
      notify({ type: 'error', message: systemT('app.newApp.appCreateFailed') })
    }
  }

  return (
    <a
      ref={ref}
      className={classNames(!disabled && style.listItem, !disabled && style.newItemCard, disabled && style.disabledNewItemCard)}
      onClick={
        () => {
          if (disabled)
            return
          setShowNewAppDialog(true)
        }
      }
    >
      <div className={style.listItemTitle}>
        <span className={disabled ? style.newItemIconDisabled : style.newItemIcon}>
          <span className={classNames(style.newItemIconImage, style.newItemIconAdd)} />
        </span>
        <div className={classNames(style.listItemHeading, style.newItemCardHeading)}>
          {systemT('app.createApp')}
        </div>
      </div>
      {/* <div className='text-xs text-gray-500'>{systemT('app.createFromConfigFile')}</div> */}
      {/* <SettingsModal
        isSetting={false}
        appInfo={null}
        isShow={showNewAppDialog}
        onClose={() => setShowNewAppDialog(false)}
        onCreate={onCreateApp}
      /> */}
      {showNewAppDialog && (
        <SettingsModal
          isSetting={false}
          appInfo={null}
          isShow={showNewAppDialog}
          onClose={() => {
            setShowNewAppDialog(false)
            if (searchParams.get('shopify_bind_id')) {
              // 回到首页
              router.replace('/apps')
            }
          }}
          onCreate={onCreateApp}
        />
      )}
      <CreateFromDSLModal
        show={showCreateFromDSLModal}
        onClose={() => {
          setShowCreateFromDSLModal(false)

          if (dslUrl)
            replace('/')
        }}
        activeTab={activeTab}
        dslUrl={dslUrl}
        onSuccess={() => {
          onPlanInfoChanged()
          if (onSuccess)
            onSuccess()
        }}
      />
    </a>
  )
})

CreateAppCard.displayName = 'CreateAppCard'
export default CreateAppCard
